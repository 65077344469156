import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row } from "react-bootstrap"
import "../dist/css/style.css"
import c1 from "../images/values/c1.png"
import c2 from "../images/values/c2.png"
import c3 from "../images/values/c3.png"
import c4 from "../images/values/c4.png"
import c5 from "../images/values/c5.png"
import c6 from "../images/values/c6.png"
import c7 from "../images/values/c7.png"
import c8 from "../images/values/c8.png"

const Values = () => {
  return (
    <Layout pageName="Values">
      <SEO title="Values" />
      <Container className="pb-5">
        <Row className="pb-lg-5 ">
          <Container className="posrel text-center my-5">
            <p className="ed-title">The Values</p>
            <p className="fi-head">We are deeply rooted in the 8Cs</p>
          </Container>
          <div className="col-lg-3 col-md-6 pb-5">
            <img src={c1} className="max-80 d-block mx-auto" />
            <p className="text-center fi-txt bolder">Collaboration</p>
          </div>
          <div className="col-lg-3 col-md-6 pb-5">
            <img src={c2} className="max-80 d-block mx-auto" />
            <p className="text-center fi-txt bolder">Communication</p>
          </div>
          <div className="col-lg-3 col-md-6 pb-5">
            <img src={c3} className="max-80 d-block mx-auto" />
            <p className="text-center fi-txt bolder">Compassion</p>
          </div>
          <div className="col-lg-3 col-md-6 pb-5">
            <img src={c4} className="max-80 d-block mx-auto" />
            <p className="text-center fi-txt bolder">Consciousness</p>
          </div>
          <div className="col-lg-3 col-md-6 pb-5">
            <img src={c5} className="max-80 d-block mx-auto" />
            <p className="text-center fi-txt bolder">Courage</p>
          </div>
          <div className="col-lg-3 col-md-6 pb-5">
            <img src={c6} className="max-80 d-block mx-auto" />
            <p className="text-center fi-txt bolder">Creativity</p>
          </div>
          <div className="col-lg-3 col-md-6 pb-5">
            <img src={c7} className="max-80 d-block mx-auto" />
            <p className="text-center fi-txt bolder">Critical thinking</p>
          </div>
          <div className="col-lg-3 col-md-6 pb-5">
            <img src={c8} className="max-80 d-block mx-auto" />
            <p className="text-center fi-txt bolder">Curiosity</p>
          </div>
        </Row>
      </Container>
    </Layout>
  )
}

export default Values
